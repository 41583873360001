import React from "react";
import ReactDOM from "react-dom/client";
import {
  Bridge,
  bootstrap,
  themeLight,
  ThemeProvider,
  createWagmiProvider,
} from "@layerzerolabs/x-trader-joe-bridge";

import { wagmi } from "./config/wagmi";
import { mainnet } from "./config/mainnet";
import { ConnectButton, RainbowKitProvider } from "@rainbow-me/rainbowkit";

import "./styles/reset.css";
import "./styles/fonts.css";
import "@rainbow-me/rainbowkit/styles.css";
import "react-toastify/dist/ReactToastify.css";
import { WagmiConfig } from "wagmi";
//import Layout from "./components/Layout"; // Zmiana tutaj
import Home from "./pages/home/home";
import Menu from "./components/menu/menu";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";

// boostrap the bridge
bootstrap(mainnet, {
  evm: wagmi.wagmiAdapter,
});

const EthereumAdapterProvider = createWagmiProvider(
  wagmi
) as React.FC<React.PropsWithChildren>;

const App = () => {
  return <Home />;
};

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

//console.log(process.env.REACT_APP_MY_VAR);

root.render(
  <>
    <WagmiConfig client={wagmi.wagmiClient as any}>
      <RainbowKitProvider chains={wagmi.wagmiChains}>
        <Router>
          <Routes>
            <Route path="/" element={<Home />} />
            {/* <Route path="/resources" element={<Resources />} /> */}
          </Routes>
          {/* <ConnectButton />
        <App /> */}
        </Router>
      </RainbowKitProvider>
    </WagmiConfig>
  </>
);
