import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import "./menu.css";
import { ConnectButton, RainbowKitProvider } from "@rainbow-me/rainbowkit";
import MenuNav from "./menu-nav/menu-nav";

const Menu: React.FC = () => {
  const [isHamburgerMenuOpen, setIsHamburgerMenuOpen] = useState(false);

  const toggleHamburgerMenu = () => {
    setIsHamburgerMenuOpen(!isHamburgerMenuOpen);
  };

  return (
    <main className="menu__main-container">
      <div
        className={`menu__main ${
          !isHamburgerMenuOpen ? "menu__in-hamburger-menu" : ""
        }`}
      >
        <div className="menu__wrapper">
          <div className="menu__left-side">
            <a id="rebase-logo-header">
              <img
                src="/images/components/menu/menu-rebase-logo.svg"
                className="menu__rebase-logo"
                alt="rebase logo"
              />
            </a>
            {/* <app-main-menu className="app-main-menu" ></app-main-menu> */}
            <div className="menu__app-main-menu">
              <MenuNav></MenuNav>
            </div>
          </div>
        </div>

        {isHamburgerMenuOpen && (
          <img
            className="menu__hamburger"
            src="/images/components/menu/close.svg "
            alt="hamburger menu"
            onClick={toggleHamburgerMenu}
          />
        )}

        {!isHamburgerMenuOpen && (
          <img
            className="menu__hamburger"
            src="/images/components/menu/hamburger.svg "
            alt="hamburger menu"
            onClick={toggleHamburgerMenu}
          />
        )}
      </div>

      {isHamburgerMenuOpen && (
        <div className="menu__hamburger-menu">
          <div className="menu__top">
            <MenuNav></MenuNav>
          </div>
          {/* <app-socials></app-socials> */}
        </div>
      )}
    </main>
  );
};

export default Menu;
