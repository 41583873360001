import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import "./menu-nav.css";
import { MenuItemData } from "./menu-item/menu-item.model";
import MenuItem from "./menu-item/menu-item";
import { ConnectButton } from "@rainbow-me/rainbowkit";

const menuItems = [
  { label: "Home", link: process.env.REACT_APP_LINK_REBASE_GG + "/home" },
  //   {
  //     label: "About",
  //     submenu: [
  //       { label: "Programs", route: "/about" },
  //       { label: "App Details", route: "/app" },
  //       { label: "Partners", route: "/programs" },
  //     ],
  //   },
  //   {
  //     label: "Tokens",
  //   },
  //   // { label: 'Leaderboard', route: '/rebase-leaderboards' },
  //   {
  //     label: "News",
  //     route: "/news", // when uncomment submenu remove this line
  //     //   submenu: [
  //     //     { label: 'News', route: '/news', routePattern: '/news(/.+)?$' },
  //     //     { label: 'Tutorials' },
  //     //   ],
  //   },
] as MenuItemData[];

const MenuNav: React.FC = () => {
  const [selectedMenuItem, setSelectedMenuItem] = useState<MenuItemData | null>(
    null
  );

  const menuSelectionHandler = (item: MenuItemData) => {
    setSelectedMenuItem(item);
  };

  return (
    <nav className="menu_nav">
      {menuItems.map((item) => (
        <MenuItem
          key={item.label}
          item={item}
          onSelection={menuSelectionHandler}
          otherItemOpened={selectedMenuItem}
        />
      ))}

      <div className="menu__connectButton">
        <ConnectButton />
      </div>
    </nav>
  );
};

export default MenuNav;
