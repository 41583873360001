import React from "react";
// import { NavLink } from "react-router-dom";
import "./menu-item.css";
import { MenuItemData } from "./menu-item.model";

interface AppMenuItemProps {
  item: MenuItemData;
  onSelection: (item: MenuItemData) => void;
  otherItemOpened: MenuItemData | null;
}

const MenuItem: React.FC<AppMenuItemProps> = ({
  item,
  onSelection,
  otherItemOpened,
}) => {
  const handleClick = () => {
    console.log("handleClick", { item });
    if (item.link && !item.route) {
      (window as any).location = item.link;
    }
    onSelection(item);
  };

  //return <div className="menu__item" onClick={handleClick}>{item.label}</div>;
  return (
    <button className="menu__item__menuItemButton" onClick={handleClick}>
      {item.label}
    </button>
  );
};

export default MenuItem;
