import React from "react";
import { NavLink } from "react-router-dom";
import "./footer.css";
import Socials from "../socials/socials";

const Footer: React.FC = () => {
  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  return (
    <main className="footer__main">
      <div className="footer__innerMain">
        <div>
          <a id="rebase-logo-footer">
            <img
              src="/images/components/menu/rebase-logo.svg"
              width="163"
              height="32"
              alt="rebase logo"
            />
          </a>
          <span>Discover Digital Collectibles in the Real World</span>

          <div className="socials">
            {/* <app-socials></app-socials> */}
            <Socials></Socials>
          </div>
          <nav>
            <ul>
              <li>
                <a href={process.env.REACT_APP_LINK_REBASE_GG + "/home"}>
                  Home
                </a>
              </li>
              <li>
                <a href={process.env.REACT_APP_LINK_REBASE_GG + "/about"}>
                  About
                </a>
              </li>
              <li>
                <a href={process.env.REACT_APP_LINK_REBASE_GG + "/staking"}>
                  Tokens
                </a>
              </li>
              <li>
                <a href={process.env.REACT_APP_LINK_REBASE_GG + "/app"}>
                  App Details
                </a>
              </li>
              <li>
                <a href={process.env.REACT_APP_LINK_REBASE_GG + "/programs"}>
                  Programs
                </a>
              </li>
              <li>
                <a
                  href={
                    process.env.REACT_APP_LINK_REBASE_GG + "/privacy-policy"
                  }
                >
                  Privacy Policy
                </a>
              </li>
              <li>
                <a
                  href={process.env.REACT_APP_LINK_REBASE_GG + "/terms-of-use"}
                >
                  Terms of Use
                </a>
              </li>
              <li>
                <a
                  href={process.env.REACT_APP_LINK_REBASE_GG + "/how-to-delete"}
                >
                  How to Delete
                </a>
              </li>
            </ul>
          </nav>
        </div>
        {/* <button className="desktop white" onClick={scrollToTop}>
          Back to the top
        </button>
        <button className="mobile white" onClick={scrollToTop}>
          Up
        </button>{" "} */}
      </div>
    </main>
  );
};

export default Footer;
