import React from "react";
import Menu from "../menu/menu";
import "./layout.css";
import Footer from "../footer/footer";

const Layout: React.FC<React.PropsWithChildren> = ({ children }) => {
  return (
    <div className="layout__main">
      <Menu />
      <div className="layout__content">{children}</div>
      <Footer />
    </div>
  );
};

export default Layout;
