import React from "react";
import {
  Bridge,
  ThemeProvider,
  themeLight,
} from "@layerzerolabs/x-trader-joe-bridge";
import Layout from "../../components/layout/layout";
import EthereumAdapterProvider from "../../EthereumAdapterProvider";
import Tab from "../../components/tab/tab";
import "./home.css";

const Home = () => {
  return (
    <Layout>
      <div className="tab__container">
        <Tab>
          <EthereumAdapterProvider>
            <ThemeProvider theme={themeLight}>
              <div className="bridge-css-changer">
                <Bridge />
              </div>
            </ThemeProvider>
          </EthereumAdapterProvider>
        </Tab>
      </div>
    </Layout>
  );
};

export default Home;
