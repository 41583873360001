import { ChainId } from "@layerzerolabs/lz-sdk";
import { Token } from "@layerzerolabs/x-trader-joe-bridge";

const tokens = [
  new Token(
    ChainId.ETHEREUM,
    "0xa80221D067603e30060F75E2458AA361f8eE5402",
    18,
    "IRL"
  ),
  new Token(
    ChainId.BSC,
    "0xa80221D067603e30060F75E2458AA361f8eE5402",
    18,
    "IRL"
  ),
];

const proxy = [
  {
    chainId: ChainId.ETHEREUM,
    address: "0x02B88B31492C19d9e3145934cDb63a8a9B5b4B10",
  },
];

export const mainnet = { tokens, proxy };
