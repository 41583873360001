import React from "react";
import "./tab.css";

const Tab: React.FC<React.PropsWithChildren> = ({ children }) => {
  return (
    <div className="tab_main">
      <div className="tab__tabTitle">
        <div className="tab__tabTitle__leftPart">Transfer</div>
        <div className="tab__tabTitle__rightPart">
          <svg width="60" height="48">
            <path d="M0,0 C20,0 40,48 60,48 L60,48 L0,48 Z" fill="white" />
          </svg>
        </div>
      </div>

      <div className="tab__content">{children}</div>
    </div>
  );
};

export default Tab;
